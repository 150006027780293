import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { H6, Small, Tiny } from "./Typography";
import { useTheme } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import WarningModal from "./modal/alerts/WarningModal";
import { useSelector } from "react-redux";
import SurveyJdoctor from "page/modal/survey-jdoctor";
import SurveyModal from "page/modal/survey-modal2";
import CompleteModal from "page/modal/alerts/SuccessModal";
import LoginModalSimple from "page/modal/login-simple";
import LoginModal from "page/modal/login-modal";
import { setLogin } from "reducer/authReducer";
import { useDispatch } from "react-redux";

const menuItems = [
  {
    icon: "/static/bottombar/menu1.svg",
    selectedIcon: "/static/bottombar/menu1-selected.svg",
    title: "클래스",
    url: "/naver/jiwongumMain2",
  },
  {
    icon: "/static/bottombar/menu2.svg",
    selectedIcon: "/static/bottombar/menu2-selected.svg",
    title: "커뮤니티",
    url: "/naver/community",
  },
  {
    icon: <KeyboardArrowUpIcon />,
    selectedIcon: <KeyboardArrowUpIcon />,
    title: "",
    url: "/setting/my",
  },
  {
    icon: "/static/bottombar/menu3-selected.svg",
    selectedIcon: "/static/bottombar/menu3.svg",
    title: "기업리포트",
    url: "/naver/report-creator",
  },
  {
    icon: "/static/bottombar/menu4-selected.svg",
    selectedIcon: "/static/bottombar/menu4  .svg",
    title: "실무요청",
    url: "comingsoon",
  },
];

export default function BottomBar({ setShowKaKaoLoginModal }) {
  const setting = useSelector((state) => state.Sidebar.setting);
  const account = useSelector((state) => state.Auth.account);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentIndex = menuItems.findIndex(
      (item) => item.url === location.pathname
    );
    setSelectedMenuItem(currentIndex !== -1 ? currentIndex : null);
  }, [location]);

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
  };
  const isInsurance = window.location.hostname == 'xn--3j1bu51b3tg.com' || window.location.hostname == 'sugar.ceo' ? true : false

  const [showSurveyModal, setShowSurveyModal] = useState(false);

  const handleMenuClick = (item) => {
    if (item.title == "상담신청") {
      setShowSurveyModal(true);
    } else {
      if (!item?.url) {
        setWarningModalOpen(true);
        return;
      }
      navigate(item.url);
    }
  };

  const [success, setSuccess] = useState(false);

  const [userToken, setUserToken] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [warning2, setWarning2] = useState(false);

  const loginKakao = () => {
    window.open('https://kauth.kakao.com/oauth/authorize?client_id=' + process.env.REACT_APP_KAKAO_LOGIN_CLIENT_KEY + '&redirect_uri=' + process.env.REACT_APP_API_URI + '/v4/auth/login/redirect/&response_type=code', "", "width=300px, height=300")
    window.addEventListener('message', ((e) => {
      if (e.data.type == "login") {
        setUserAccount(e.data.user)
        setUserToken(e.data.access_token)
        setTimeout(() => {
          setSuccess(true)
        }, 10)
      } else if (e.data.type == "reject") {
        setWarning2(true)
      }
    }), false);
  }
  const [showLoginModal, setShowLoginModal] = useState(false);
  const login = () => {
    setShowLoginModal(true)
    return
  }
  return (
    <>
    <CompleteModal showModal={success} setShowModal={setSuccess} title={"로그인 성공"} description={"즐거운 시간되세요 :)"} onAction={() => {
      setShowLoginModal(false)
      setTimeout(() => {
        dispatch(setLogin(userToken, userAccount))
      }, 10)
    }} />
    <WarningModal showModal={warning2} setShowModal={setWarning2} title={"카카오톡 인증에 실패했습니다."} description={""} action={""} />
    {
      window.location.hostname == "booksense.club" ? (
        <LoginModalSimple open={showLoginModal} setShowLoginModal={setShowLoginModal} />
      ) : (
        <LoginModal open={showLoginModal} setShowLoginModal={setShowLoginModal} />
      )
    }
      {isInsurance ? <SurveyJdoctor open={showSurveyModal} setShowModal={setShowSurveyModal} /> : <SurveyModal open={showSurveyModal} setShowModal={setShowSurveyModal} />}
      <WarningModal
        showModal={warningModalOpen}
        setShowModal={setWarningModalOpen}
        title="경고"
        messages={["아직 준비중인 서비스입니다."]}
      />

      <AppBar
        position="fixed"
        style={{
          backgroundColor: "transparent",
          top: "auto",
          bottom: 0,
        }}
      >
        <Toolbar sx={{ padding: 0 }}>
          {/* Add a shadow container */}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-end",
            }}
          >
          {
            setting?.bottom_bar?.map((item, index) => (
              <Box
                key={`shadow-${index}`}
                sx={{
                  flex: 1,
                  height: index === 2 ? "0px" : "100%",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
                  borderTopLeftRadius: index === 3 ? "20px" : 0,
                  borderTopRightRadius: index === 1 ? "20px" : 0,
                  ...(index === 2 && {
                    borderRadius: "30px",
                    maxWidth: "64px",
                    width: "64px",
                    marginBottom: "24px",
                  }),
                }}
              />
            ))
          }
          </Box>

          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="flex-end"
            width="100%"
            position="relative"
            zIndex={1}
          >
            {setting?.bottom_bar?.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  if (index == 2) {
                    if (account) {
                      window.location.href = "/setting/my"
                    } else {
                      if (window.location.hostname == "booksense.club") {
                        login()
                      } else {
                        loginKakao()
                      }
                    }
                  } else {
                    handleMenuClick(item);
                  }
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "8px",
                  backgroundColor: "#ffffff",
                  flex: 1,

                  ...(index === 2 && {
                    position: "relative",
                    marginRight: "4px",
                    marginLeft: "4px",
                    minWidth: "54px",
                    height: "54px",
                    minHeight: "54px",
                    backgroundColor: "transparent",
                  }),
                  ...(index === 1 && {
                    borderTopRightRadius: "20px",
                  }),
                  ...(index === 3 && {
                    borderTopLeftRadius: "20px",
                  }),
                }}
              >
                <IconButton sx={{ padding: 0, marginBottom: "4px" }}>
                  {index === 2 ? (
                    <></>
                  ) : (
                    <img
                  src={item.url == window.location.pathname ? require('assets/icon/'+item.selectedIcon) : require('assets/icon/'+item.icon)}
                  alt={item.title}
                  style={{ height: item.height }}
                />
                  )}
                </IconButton>
                <Small
                  color={
                    index === 2
                      ? "white"
                      : selectedMenuItem === index
                      ? "#5313BB"
                      : theme.palette.primary.dark
                  }
                  sx={{
                    fontSize: index === 2 ? "14px" : "11px",
                    position: index === 2 ? "absolute" : "relative",
                    bottom: index === 2 ? "14px" : "0",
                    left: index === 2 ? "50%" : "0",
                    transform: index === 2 ? "translateX(-50%)" : "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  {index === 2 ? "" : item.title}
                </Small>
                {index === 2 && (
                  <>
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "30px",
                        marginBottom: "48px",
                        minWidth: "54px",
                        height: "54px",
                        minHeight: "54px",
                        backgroundColor: "#4E2BF4",
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <KeyboardArrowUpIcon
                        sx={{
                          color: "white",
                          fontSize: "18px",
                          animation: "moveUpDown 1s ease-in-out infinite",
                          "@keyframes moveUpDown": {
                            "0%, 100%": {
                              transform: "translateY(0)",
                            },
                            "50%": {
                              transform: "translateY(-4px)",
                            },
                          },
                        }}
                      />
                      <Tiny
                        sx={{
                          color: "white",
                          marginBottom: "15px",
                          fontSize: "15px",
                        }}
                      >
                        MY
                      </Tiny>
                    </Box>
                    <img
                      src="/static/curve4.svg"
                      alt="curve"
                      style={{
                        position: "absolute",
                        bottom: "9px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "71px",
                        height: "auto",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "-10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        height: "20px",
                        width: "70px",
                        backgroundColor: "white",
                      }}
                    ></Box>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
