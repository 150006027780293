import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v1/NaverLayout";
import LayoutV3 from "layouts/layout-v1/WrtnLayout";
import LayoutV4 from "layouts/layout-v1/BoardLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

////////////////////////////////////////////////////

const Main = Loadable(lazy(() => import("./page/main")));
const Board = Loadable(lazy(() => import("./page/board")));
const Error = Loadable(lazy(() => import("./page/error")));
const VideoCreator = Loadable(lazy(() => import("./page/create-video")))
const CourseList = Loadable(lazy(() => import("./page/course-list")))
const CourseMain = Loadable(lazy(() => import("./page/course-main")))
const CourseSub = Loadable(lazy(() => import("./page/course-sub")))
const CourseNoticeDetail = Loadable(lazy(() => import("./page/course-notice-detail")))
const CourseNotice = Loadable(lazy(() => import("./page/course-notice")))
const Course = Loadable(lazy(() => import("./page/course")))
const Post = Loadable(lazy(() => import("./page/post")))
const TextEditor = Loadable(lazy(() => import("./page/text-editor")));
const NameCardEditor = Loadable(lazy(() => import("./page/namecard-editor")));
const ProfileEditor = Loadable(lazy(() => import("./page/profile-editor")));
const UserList = Loadable(lazy(() => import("./page/user-list")));
const TaxList = Loadable(lazy(() => import("./page/tax-list")));
const BannerEditor = Loadable(lazy(() => import("./page/banner-editor")));
const SidebarEditor = Loadable(lazy(() => import("./page/sidebar-editor")));
const SidebarEditor2 = Loadable(lazy(() => import("./page/sidebar-editor2")));
const BizAnno = Loadable(lazy(() => import("./page/bizanno")));
const BizDetail = Loadable(lazy(() => import("./page/bizdetail")));
const Progress = Loadable(lazy(() => import("./page/progress")));
const ProgressReport = Loadable(lazy(() => import("./page/progress-report")));
const ProgressSummary = Loadable(lazy(() => import("./page/progress-summary")));
const ProgressFcList = Loadable(lazy(() => import("./page/progress-fc-list")));
const Wrtn = Loadable(lazy(() => import("./page/wrtn")));
const Cretop = Loadable(lazy(() => import("./page/cretop")));
const CretopDB = Loadable(lazy(() => import("./page/cretop-db")));
const Namecard = Loadable(lazy(() => import("./page/namecard")));
const WorkSchedule = Loadable(lazy(() => import("./page/work-schedule")));
const AutoGenerate = Loadable(lazy(() => import("./page/APImanagement")));
const CommunityMain = Loadable(lazy(() => import("./page/community-main")));
const Kmong = Loadable(lazy(() => import("./page/kmong")))
const LectureMain = Loadable(lazy(() => import("./page/lecture-main")))


const CommunityCategory = Loadable(
  lazy(() => import("./page/community/community-category"))
);

const CommunityEditor = Loadable(
  lazy(() => import("./page/community/editor"))
);

const CommunityDetail = Loadable(
  lazy(() => import("./page/community/community-detail"))
);

const ProfileOther = Loadable(
  lazy(() => import("./page/community/profile-other"))
);

const LikedClasses = Loadable(
  lazy(() => import("./page/community/liked-classes"))
);

const Classes = Loadable(lazy(() => import("./page/community/classes")));
const MyInfo = Loadable(lazy(() => import("./page/community/myinfo")));
const PaymentHistory = Loadable(
  lazy(() => import("./page/community/payment-history"))
);
const Followers = Loadable(lazy(() => import("./page/community/followers")));
const Following = Loadable(lazy(() => import("./page/community/following")));

const SurveyList = Loadable(lazy(() => import("./page/survey-list")));
const ReportCreator = Loadable(lazy(() => import("./page/report-creator")));

const PayTest = Loadable(lazy(() => import("./page/pay-test")));

const SalesV2 = Loadable(lazy(() => import("./page/sales-v2")));

const CourseBuyFail = Loadable(lazy(() => import("./page/course/fail")));
const CourseBuySuccess = Loadable(lazy(() => import("./page/course/success")));




/////////////////////////////////////////////////////////////////////////

const InsuranceTable = Loadable(
  lazy(() => import("./page/manager/insurance-table"))
);

const InsuranceTableLoan = Loadable(
  lazy(() => import("./page/manager/insurance-table-loan"))
);

const InsuranceUserManagement = Loadable(
  lazy(() => import("./page/manager/insurance-user-management"))
);

const InsuranceUserSurvey = Loadable(
  lazy(() => import("./page/manager/insurance-user-survey"))
);

const InsuranceProgress = Loadable(
  lazy(() => import("./page/manager/insurance-progress"))
);

const InsuranceProgressReport = Loadable(
  lazy(() => import("./page/manager/insurance-progress-report"))
);

const InsuranceProgressSummary = Loadable(
  lazy(() => import("./page/manager/insurance-progress-summary"))
);

const InsuranceProgressFCList = Loadable(
  lazy(() => import("./page/manager/insurance-progress-fc-list"))
);

const InsuranceBannerEditor = Loadable(
  lazy(() => import("./page/manager/insurance-banner-editor"))
);

const InsuranceVideoCreator = Loadable(
  lazy(() => import("./page/manager/insurance-video-creator"))
)
const routes = () => {
  return [
    {
      path: "/",
      element: <LayoutV2 />,
      children: [
        {
          path: "/",
          element: <Kmong />
        },
      ]
    },
    {
      path: '/manage',
      element: <LayoutV2 />,
      children: [
        {
          path: "table",
          element: <InsuranceTable />,
        },
        {
          path: "table-loan",
          element: <InsuranceTableLoan />,
        },
        {
          path: 'user-list',
          element: <InsuranceUserManagement />,
        },
        {
          path: 'banner-editor',
          element: <InsuranceBannerEditor />,
        },
        {
          path: "survey-list",
          element: <InsuranceUserSurvey />,
        },
        {
          path: "progress-team",
          element: <InsuranceProgress />
        },
        {
          path: "progress-company",
          element: <InsuranceProgress />
        },
        {
          path: "progress-report",
          element: <InsuranceProgressReport />
        },
        {
          path: "progress-fc",
          element: <InsuranceProgressFCList />
        },
        {
          path: "progress-view/*",
          element: <InsuranceProgressReport />
        },
        {
          path: "progress-company-summary",
          element: <InsuranceProgressSummary />
        },
        {
          path: 'video-editor',
          element: <InsuranceVideoCreator/>
        }
      ]
    },
    {
      path: "/course",
      element: <LayoutV2 />,
      children: [
        {
          path: "buy/success",
          element: <CourseBuySuccess />
        },
        {
          path: "buy/fail",
          element: <CourseBuyFail />
        }
      ]
    },
    // {
    //   path: '/test',
    //   element: <LayoutV1 />,
    //   children: [
    //     {
    //       path: "",
    //       element: <SalesV2 />,
    //     }
    //   ]
    // },
    {
      path: '/board',
      element: <LayoutV2 />,
      children: [
        {
          path: "*",
          element: <CommunityMain/>,
        },
      ]
    },
    {
      path: '/lecture',
      element: <LayoutV1 />,
      children: [
        {
          path: "editor",
          element: <VideoCreator />
        },
        {
          path: "editor/*", 
          element: <VideoCreator />
        },
        {
          path: "*",
          element: <CourseList />,
        },
      ]
    },
    {
      path: '/course',
      element: <LayoutV2 />,
      children: [
        {
          path: "",
          element: <LectureMain/>,
        },
        {
          path: "*",
          element: <CourseSub />,
        },
      ]
    },
    {
      path: 'class',
      element: <LayoutV1 />,
      children: [
        {
          path: "notice/detail/*",
          element: <CourseNoticeDetail />,
        },
        {
          path: "notice/*",
          element: <CourseNotice />,
        },
      ]
    },
    {
      path: 'class',
      element: <LayoutV2 />,
      children: [
        {
          path: "*",
          element: <Course />,
        },
      ]
    },
    {
      path: '/post',
      element: <LayoutV2 />,
      children: [
        {
          path: "*",
          element: <CommunityDetail/>
        }
      ]
    },
    {
      path: '/editor',
      element: <LayoutV1 />,
      children: [
        {
          path: "*",
          element: <TextEditor />,
        }, {
          path: "",
          element: <TextEditor />,

        }]
    },
    {
      path: '/write',
      element: <LayoutV2 />,
      children: [
        {
          path: "*",
          element: <CommunityEditor />,
        }, {
          path: "",
          element: <CommunityEditor />,

        }]
    },
    {
      path: '/settings',
      element: <LayoutV1 />,
      children: [
        {
          path: "namecard",
          element: <NameCardEditor />,
        },
        {
          path: "profile",
          element: <ProfileEditor />,
        }
      ]
    },
    {
      path: '/profile',
      element: <LayoutV2 />,
      children: [
        {
          path: "*",
          element: <ProfileOther />
        },
        {
          path: "*/follower",
          element: <Followers/>
        },
        {
          path: "*/following",
          element: <Following/>
        },
        {
          path: "*/article",
          element: <ProfileOther/>
        },
        {
          path: "*/classes",
          element: <Classes/>
        }
      ]
    },
    {
      path: '/setting',
      element: <LayoutV2 />,
      children: [
        {
          path: "my",
          element: <MyInfo />
        },
        {
          path: "my/follower",
          element: <Followers/>
        },
        {
          path: "my/following",
          element: <Following/>
        },
        {
          path: "my/like-class",
          element: <LikedClasses/>
        },
        {
          path: "my/article",
          element: <ProfileOther/>
        },
        {
          path: "my/payment-history",
          element: <PaymentHistory/>
        },
        {
          path: "my/classes",
          element: <Classes/>
        }
      ]
    },
    {
      path: '/p',
      element: <LayoutV1 />,
      children: [
        {
          path: "*",
          element: <Namecard/>
        }
      ]
    },
    {
      path: "/admin",
      element: <LayoutV1 />,
      children: [
        {
          path: "survey-list",
          element: <SurveyList />,
        },
        {
          path: "user-list",
          element: <UserList />,
        },
        {
          path: "tax-list",
          element: <TaxList />,
        },
        {
          path: "banner-editor",
          element: <BannerEditor />,
        },
        {
          path: "menu-editor",
          element: <SidebarEditor />,
        },
        {
          path: "menu-editor2",
          element: <SidebarEditor2 />,
        },
        {
          path: "server-manager",
          element: <AutoGenerate />,
        }
      ],
    },
    {
      path: "/bizanno",
      element: <LayoutV1 />,
      children: [
        {
          path: "",
          element: <BizAnno />,
        },
        {
          path: "*",
          element: <BizAnno />,
        }
      ],
    },
    {
      path: "/bizdetail",
      element: <LayoutV1 />,
      children: [
        {
          path: "*",
          element: <BizDetail />,
        }
      ],
    },
    {
      path: "/progress",
      element: <LayoutV1 />,
      children: [
        {
          path: "team",
          element: <Progress />
        },
        {
          path: "company",
          element: <Progress />
        },
        {
          path: "report",
          element: <ProgressReport />
        },
        {
          path: "fc",
          element: <ProgressFcList />
        },
        {
          path: "view/*",
          element: <ProgressReport />
        },
        {
          path: "company_summary",
          element: <ProgressSummary />
        }
      ]
    },
    {
      path: "/multiai",
      element: <LayoutV3 />,
      children: [
        {
          path: '1',
          element: <Wrtn />
        },
        {
          path: '5',
          element: <Wrtn />
        },
        {
          path: '15',
          element: <Wrtn />
        }
      ]
    },
    {
      path: "/cretop",
      element: <LayoutV2 />,
      children: [
        {
          path: "",
          element: <ReportCreator />,
        }
      ]
    },
    {
      path: "/cretop",
      element: <LayoutV1 />,
      children: [
        {
          path: "db",
          element: <CretopDB />,
        }
      ]
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};
export default routes;
