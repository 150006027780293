const MenuData = [
  {
    title: "메뉴",
    sectionTitle: true,
  },
  {
    title: "메인배너 수정",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/banner-editor",
    view_need_type: '이사'
  },
  {
    title: "강의 올리기",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/video-editor",
    view_need_type: '매니저'
  },
  {
    title: "사용자 관리",
    icon: "/static/sidemenu/paper.png",
    selectedIcon: "/static/sidemenu/paper-selected.png",
    path: "/manage/user-list",
    view_need_type: '매니저'
  },
];

export { MenuData };
